import request from '@/utils/request'

export function queryMealCategories(parameter) {
  return request({
    url: '/service/package/type',
    method: 'POST',
    data: parameter
  })
}

export function getMealCategory(parameter) {
  return request({
    url: '/service/package/type',
    method: 'get',
    params: parameter
  })
}

export function deleteMealCategory(parameter) {
  return request({
    url: '/service/package/type/status',
    method: 'POST',
    params: parameter
  })
}

export function putMealCategory(parameter) {
  return request({
    url: '/service/package/type',
    method: 'PUT',
    data: parameter
  })
}

export function putMealCategoryPost(parameter) {
  return request({
    url: '/service/package/type/update/config',
    method: 'POST',
    data: parameter
  })
}

export function queryMeal(parameter) {
  return request({
    url: '/service/package',
    method: 'POST',
    data: parameter
  })
}

export function deleteMeal(parameter) {
  return request({
    url: '/service/package/status',
    method: 'POST',
    params: parameter
  })
}

export function putMeal(parameter) {
  return request({
    url: '/service/package',
    method: 'PUT',
    data: parameter
  })
}

export function getMeal(parameter) {
  return request({
    url: '/service/package',
    method: 'get',
    params: parameter
  })
}



