<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <div>
      <div class='table-operator'>
        <a-button type='primary' icon='plus' @click='handleNew(1)'>新增常规套餐</a-button>
        <a-divider type='vertical' />
        <!-- <a-button type='primary' icon='plus' @click='handleNew(2)'>新增专属套餐</a-button>
        <a-divider type='vertical' /> -->
        <a-button type='primary' icon='plus' @click='handleNew(3)'>新增补贴套餐</a-button>
        <a-divider type='vertical' />
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
      </div>

      <s-table ref='table' rowKey='id' size='default' :columns='columns' :data='loadData'>
        <span slot='id' slot-scope='text, record'>
          <template>
            <a @click='handleEdit(record)'>
              <ellipsis :length='10' tooltip>{{ text }}</ellipsis>
            </a>
          </template>
        </span>
        <span slot='cover' slot-scope='text'>
          <template>
            <img :src='text' width='180px'>
          </template>
        </span>
        <span slot='status' slot-scope='text'>
          <template>
            <a-badge v-if='text=="online"' dot status='processing' :text='"上架"' />
            <a-badge v-if='text=="offline"' dot status='warning' :text='"下架"' />
          </template>
        </span>
        <span slot='action' slot-scope='text, record'>
          <template>
            <a-space>
              <a v-if='record.status=="online"' @click='handleEdit(record)'>编辑</a>
              <a v-if='record.status=="online"' @click='handleStatus(record,"offline")'>下架</a>
              <a v-if='record.status=="offline"' @click='handleStatus(record,"online")'>上架</a>
              <a v-if='record.status=="offline"' @click='handleDelete(record)'>删除</a>
            </a-space>
          </template>
        </span>
      </s-table>
    </div>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { queryMeal, deleteMeal, putMeal } from '@/api/meal'

const columns = [
  {
    title: '套餐id',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    title: '套餐名称',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' }
  },
  {
    title: '套餐类型',
    dataIndex: 'servicePackageTypeName',
    scopedSlots: { customRender: 'servicePackageTypeName' }
  },
  {
    title: '套餐价格',
    dataIndex: 'servicePackageTypePrice',
    scopedSlots: { customRender: 'servicePackageTypePrice' }
  },
  {
    title: '套餐封面',
    dataIndex: 'cover',
    scopedSlots: { customRender: 'cover' }
  },
  {
    title: '商品数量',
    dataIndex: 'productCount',
    scopedSlots: { customRender: 'productCount' }
  },
  {
    title: '套餐状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '优先级排序',
    dataIndex: 'priority',
    scopedSlots: { customRender: 'priority' }
  },
  {
    title: '操作',
    fixed: 'right',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'ProductionMealList',
  components: {
    STable, Ellipsis
  },
  data() {
    return {
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return queryMeal(Object.assign(parameter, this.queryParam))
          .then(datum => {
            return datum
          })
      }
    }
  },
  created() {

  },
  methods: {
    handleNew(type) {
      this.$router.push({ path: '/production/meal/edit', query: {type: type}})
    },
    handleEdit(record) {
      this.$router.push({ path: '/production/meal/edit', query: { id: record.id } })
    },
    handleStatus(record, status) {
      putMeal(Object.assign({}, record, { status: status })).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    handleDelete(record) {
      deleteMeal({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    }
  }
}
</script>
